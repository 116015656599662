let stripe = null;
let card = null;

function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  const form = document.getElementById("payment-form");
  const hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", "stripe_token");
  hiddenInput.setAttribute("value", token.id);
  form.appendChild(hiddenInput);

  // Submit the form
  form.submit();
}

function setupBillingForm() {
  const elements = stripe.elements();

  card = elements.create("card", {
    style: {
      base: {
        color: "#495057",
        fontFamily: "system-ui",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "normal"
      }
    }
  });
  card.mount("#card-element");
  card.addEventListener("change", function onChangeCard(event) {
    const displayError = document.getElementById("card-errors");
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = "";
    }
  });
  const form = document.getElementById("payment-form");
  form.addEventListener("submit", function onSubmit(event) {
    event.preventDefault();

    stripe
      .createToken(card, {
        name: document.getElementById("name").value
      })
      .then(function onCreateToken(result) {
        if (result.error) {
          // Inform the user if there was an error.
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
          // re-enable submit button (got disabled via data-disabled-with attribute)
          document.querySelector("button[type=submit]").textContent = "Save";
          document.querySelector("button[type=submit]").disabled = false;
        } else {
          // Send the token to your server.
          stripeTokenHandler(result.token);
        }
      });
  });

  document.querySelector(".js-payment-card").classList.remove("d-none");
  document.querySelector(".js-payment-loading").classList.add("d-none");
}

function loadStripe() {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://js.stripe.com/v3/";
  document.body.appendChild(script);
  script.onload = function onloadStripe() {
    stripe = window.Stripe(process.env.STRIPE_TOKEN);
    setupBillingForm();
  };
}

document.addEventListener("turbolinks:load", function onLoadForStripe() {
  if (!document.querySelector("#card-element")) {
    card = null;
    return;
  }
  if (!stripe) {
    loadStripe();
  } else {
    setupBillingForm();
  }
});

window.addEventListener("resize", function onResizeForStripe() {
  if (!card) return;
  if (window.innerWidth <= 768) {
    card.update({ style: { base: { fontSize: "14px" } } });
  } else {
    card.update({ style: { base: { fontSize: "16px" } } });
  }
});
