let vncWs;

function adjustVNCViewerSize() {
  const el = document.getElementById("zeromac-screen");
  if (!el) return;
  const s = getComputedStyle(el.parentNode);
  let w =
    el.parentNode.clientWidth -
    parseFloat(s.paddingLeft) -
    parseFloat(s.paddingRight);
  w = w < 320 ? 320 : w;
  el.style.width = `${w}px`;
  el.style.height = `${w * 0.75}px`;
}

function vncConnect() {
  const screen = document.getElementById("zeromac-screen");
  if (!screen) return;
  const vncWsAddr = screen.getAttribute("data-ws-addr");
  if (!vncWsAddr) return;

  adjustVNCViewerSize();
  vncWs = new RFB(screen, vncWsAddr);
  vncWs.viewOnly = false;
  vncWs.scaleViewport = "local";
}

document.addEventListener("turbolinks:load", () => {
  if (vncWs) {
    vncWs.disconnect();
    vncWs = null;
  }
  vncConnect();
});

window.addEventListener("resize", adjustVNCViewerSize);
