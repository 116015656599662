function onSSHSelectionChange(e) {
  const sshPubkey = document.querySelector(".js-ssh-pubkey");
  const sshOptions = document.querySelector(".js-ssh-options");
  if (!sshPubkey || !sshOptions) {
    return;
  }

  if (e.target.checked) {
    if (sshOptions.classList.contains("d-none")) {
      sshOptions.classList.remove("d-none");
      sshPubkey.required = true;
    }
  } else if (!sshOptions.classList.contains("d-none")) {
    sshOptions.classList.add("d-none");
    sshPubkey.required = false;
  }
}

// var privkeyRegexp = new RegExp("-----BEGIN ");
// var pubkeyRegexp = new RegExp("^\\s*[a-z0-9\\-]+ [A-Za-z0-9/\\+\\=]+ ");
// function onSSHPubkeyChange(e) {
//   if (privkeyRegexp.test(e.target.value)) {
//     // console.log("looks like a private key");
//   } else if (pubkeyRegexp.test(e.target.value)) {
//     // console.log("seems fine");
//   } else {
//     // console.log("it's not right");
//   }
// }

function onPasswordBlur(e) {
  const passwordValidation = document.querySelector(".js-password-validation");
  if (e.target.value.length > 0 && e.target.value.length <= 4) {
    passwordValidation.textContent = "Password is too short";
    if (!passwordValidation.classList.contains("text-danger")) {
      passwordValidation.classList.add("text-danger");
    }
  }
}

function onPasswordChange(e) {
  const passwordValidation = document.querySelector(".js-password-validation");
  if (!(e.target.value.length > 0 && e.target.value.length <= 4)) {
    passwordValidation.textContent =
      "Don't forget it. It won't be displayed again.";
    if (passwordValidation.classList.contains("text-danger")) {
      passwordValidation.classList.remove("text-danger");
    }
  }
}

function generatePassword(event) {
  const button = event.target;
  button.disabled = true;
  Rails.ajax({
    type: "POST",
    url: "/generate_password",
    success: function onAjaxPassword(data) {
      const passwordField = document.querySelector("#machine_password");
      passwordField.value = data.password;
      onPasswordChange({ target: passwordField });
      button.disabled = false;
    },
    failure() {
      button.disabled = false;
    }
  });
}

function importGitHubKeys(e) {
  e.target.disabled = true;
  Rails.ajax({
    type: "POST",
    url: "/import_github_keys",
    data: `github_username=${encodeURIComponent(
      document.querySelector(".js-github-username").value
    )}`,
    success(data) {
      e.target.disabled = false;
      if (data.keys) {
        const pubkey = document.querySelector(".js-ssh-pubkey");
        pubkey.value = data.keys.join("\n");
        // eslint-disable-next-line no-undef
        $("#githubModal").modal("hide");
      } else if (data.error === "Not found") {
        // eslint-disable-next-line no-alert
        alert("GitHub username was not found.");
      }
    },
    failure() {
      // eslint-disable-next-line no-alert
      alert("Unknown error");
      e.target.disabled = false;
    }
  });
}

// function debounced(delay, fn) {
//   let timerId;
//   return function(...args) {
//     if (timerId) {
//       clearTimeout(timerId);
//     }
//     timerId = setTimeout(() => {
//       fn(...args);
//       timerId = null;
//     }, delay);
//   };
// }

let statusWs;

function showLoadingProgress() {
  if (statusWs) {
    statusWs.close();
    statusWs = null;
  }
  const statusWsAddr = document.getElementById("zeromac-status-ws");
  if (statusWsAddr) {
    // show the status screen
    const progress = document.querySelector(".js-provision-progress");
    statusWs = new WebSocket(statusWsAddr.innerText);
    statusWs.addEventListener("message", function statusWsMessage(event) {
      if (progress.style.width === "100%") {
        const transitionStyle = progress.style.transition;
        progress.style.transition = "none";
        setTimeout(function resetTransition() {
          progress.style.transition = transitionStyle;
        }, 25);
      }
      const data = JSON.parse(event.data);
      progress.style.width = `${data.Progress}%`;
      if (data.Completed || data.Failed) {
        const url = document.getElementById("zeromac-machine-page").innerText;
        Turbolinks.visit(url);
      }
    });
  }
}

document.addEventListener("turbolinks:load", function onLoadForMachine() {
  showLoadingProgress();

  const link = document.querySelector("*[data-generate-password]");
  if (link) {
    link.onclick = generatePassword;
    link.attributes.removeNamedItem("data-generate-password");
  }

  const sshCheckbox = document.querySelector(".js-ssh-toggle");
  if (sshCheckbox) {
    sshCheckbox.addEventListener("change", onSSHSelectionChange);
    sshCheckbox.classList.remove("js-ssh-toggle");
    onSSHSelectionChange({ target: sshCheckbox });
  }

  // let sshPubkeyTextarea = document.querySelector("[data-validate-key]");
  // if (sshPubkeyTextarea) {
  //   sshPubkeyTextarea.addEventListener(
  //     "input",
  //     debounced(200, onSSHPubkeyChange)
  //   );
  //   sshPubkeyTextarea.attributes.removeNamedItem("data-validate-key");
  // }

  const passwordInput = document.querySelector("[data-password-field]");
  if (passwordInput) {
    passwordInput.addEventListener("blur", onPasswordBlur);
    passwordInput.addEventListener("input", onPasswordChange);
    passwordInput.attributes.removeNamedItem("data-password-field");
  }

  const githubImportButton = document.querySelector(".js-import-github");
  if (githubImportButton) {
    githubImportButton.onclick = importGitHubKeys;
    githubImportButton.classList.remove("js-import-github");
  }

  // eslint-disable-next-line no-undef
  $('.modal[data-show-modal="true"]').modal("show");
});
