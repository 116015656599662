/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "vnc";
import "stripe";
import "intercom";

import "machine";

Rails.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", function onLoadForApp() {
  const button = document.querySelector(".js-track-signup");
  if (button) {
    button.onclick = function trackSignup() {
      ga("send", {
        hitType: "event",
        eventCategory: "Button",
        eventAction: "signup"
      });
    };
    button.classList.remove("js-track-signup");
  }
});
