/* eslint-disable */
document.addEventListener("turbolinks:load", function() {
  if (!window.Intercom) {
    loadIntercom();
  }
  if (document.querySelector("#zeromac-screen")) {
    window.Intercom("update", {
      hide_default_launcher: true
    });
  } else {
    window.Intercom("update");
  }

  // hook up intercom to support link
  let link = document.querySelector("*[data-show-intercom]");
  if (link) {
    link.onclick = function() {
      Intercom("show");
      return false;
    };
    link.attributes.removeNamedItem("data-show-intercom");
  }

  // clear intercom session when logging out
  link = document.querySelector("*[data-logout-intercom]");
  if (link) {
    link.onclick = function() {
      Intercom("shutdown");
      return true;
    };
    link.attributes.removeNamedItem("data-logout-intercom");
  }
});

function loadIntercom() {
  // this is the intercom-provided js, with the prettifier run on it
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/lo4yltzp";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}
